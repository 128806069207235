.antd-pro-charts-water-wave-waterWaveCanvasWrapper {
  width: 100px !important;
  height: 100px !important;
}
.antd-pro-charts-water-wave-text {
  position: relative;
  top: -70px;
}

.waterCluster {
  .antd-pro-charts-water-wave-text {
    font-size: 32px !important;
  }
}