.card-styles {
	margin-top: 24px;
	background: #ffffff;
	/* Card */

	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
}

.text-title {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #2f4858;
}

.card-task {
	padding: 16px 25px;
	width: 173px;
	background: #effcfc;
	border: 1px solid #02c3cd;
	border-radius: 4px;
}

.card-daily {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 12px;
	gap: 8px;
	width: 109px;
	height: 94px;
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
}

.calendar-card {
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
	gap: 12px;
	margin-bottom: 12px;
	min-width: 835px;
	color: #748ea0;
	font-weight: 500;
	font-size: 10px;
}

.title-card-daily {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}
.text-card {
	display: flex;
	align-self: center;
}
.task-title {
	font-size: 10px;
	font-weight: 500;
	margin-right: 4px;
	color: #2f4858;
}

@media screen and (max-width: 1400px) {
	.card-task {
		width: 100%;
		margin-bottom: 40px;
	}
}
