.gb-select-40px {
	
	.ant-select-selector {
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 12px;
		height: 40px !important;
		padding-top:  3px !important;
		padding-left:  7px !important;
	}
	.anticon-close {
		color: #C0B8B6
	}
	 .ant-select-selector input {
		padding-top:  3px !important;
		padding-left:  7px !important;

	}
	
}
