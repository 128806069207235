.table-hot-mortgage {
	.ant-table {
		.classname-color-blue,
		.classname-color-orange,
		.classname-color-red,
		.classname-color-yellow,
		.classname-color-green {
			a, .newCom {
				color: #2F4858 !important;
				text-decoration: underline;
				cursor: pointer;
			}
			.iconID {
				.anticon svg {
					color: #2F4858 !important;
				}
			}
		}
		td.ant-table-cell {
			padding: 8px 8px !important;
		}
	}

	th.ant-table-cell.color-filter {
		padding: 8px;
		.anticon svg {
			margin-right: 15px;
		}
	}
}

.classname-color-red {
	background-color: #E6B1B1;
	.ant-table-cell-row-hover {
		background-color: #E6B1B1 !important;
	}

	.highlight-bottom-border>td {
		border-bottom: solid 1px black !important
	}

	.highlight-top-border>td {
		border-top: solid 2px black !important;
		border-bottom: solid 2px #6d9eff !important
	}
}

.classname-color-orange {
	background-color: #E6BC95;
	.ant-table-cell-row-hover {
		background-color: #E6BC95 !important;
	}

	.highlight-bottom-border>td {
		border-bottom: solid 1px black !important
	}

	.highlight-top-border>td {
		border-top: solid 2px black !important;
		border-bottom: solid 2px #6d9eff !important
	}
}

.classname-color-yellow {
	background-color: #DFDDA9;
	.ant-table-cell-row-hover {
		background-color: #DFDDA9 !important;
	}

	.highlight-bottom-border>td {
		border-bottom: solid 1px black !important
	}

	.highlight-top-border>td {
		border-top: solid 2px black !important;
		border-bottom: solid 2px #6d9eff !important
	}
}

.classname-color-blue {
	background-color: #B2DCE6;
	.ant-table-cell-row-hover {
		background-color: #B2DCE6 !important;
	}

	.highlight-bottom-border>td {
		border-bottom: solid 1px black !important
	}

	.highlight-top-border>td {
		border-top: solid 2px black !important;
		border-bottom: solid 2px #6d9eff !important
	}
}

.classname-color-green {
	background-color: #97E3BA;
	.ant-table-cell-row-hover {
		background-color: #97E3BA !important;
	}

	.highlight-bottom-border>td {
		border-bottom: solid 1px black !important
	}

	.highlight-top-border>td {
		border-top: solid 2px black !important;
		border-bottom: solid 2px #6d9eff !important
	}
}

.page-mortgages .table-row {
	background: #ffffff;
}

.ant-layout-content {
	background: rgb(250, 254, 254);
}

.ant-layout-content {
	background: rgb(250, 254, 254);
}

.state {
	padding: 5px 0px;
	width: 120px;
	text-align: center;
	display: inline-block;
	font-weight: bold;
	text-transform: capitalize;
	border-radius: 5px;
	font-size: 0.85rem;
}

.ongoing {
	background-color: #ceafba;
	color: white;
}

.rejected {
	background-color: indianred;
	color: white;
}

.to-pre-value {
	background-color: #ffc107;
}

.mortgage-row {
	cursor: pointer;
}

.mortgage-row:hover {
	background-color: #f0f0f0;
}

.list-hp {
	border-color: #ceafba !important;
	border-style: dashed !important;
	border-top-width: 2px !important;
	padding: 1.5rem 1.25rem !important;
}

.list-hp:first-child {
	border: none !important;
}
