//Código de estilos de Diorlen
.gb-banks {
	.steps-banks {
		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}
	}

	.send-banks-denied {
		.ant-steps-item-icon {
			border: 2px solid #ff4d4f !important;
			border-width: 1px;

			.ant-steps-icon {
				display: none;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #ff4d4f !important;
				}
			}
		}

		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.send-banks-answered {
		.ant-steps-item-icon {
			border: 2px solid #52c41a !important;
			border-width: 1px;

			.ant-steps-icon {
				display: none;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a !important;
				}
			}
		}

		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.send-banks {
		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-icon {
			background: white;
			border: 2px solid #02c3cd !important;

			.ant-steps-icon {
				display: inline;
				color: #02c3cd;
				top: -2px;
				display: none;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #02c3cd;
					text-align: left;
				}
			}
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.send-banks-pending {
		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-icon {
			background: white;
			border: 2px solid #748ea0 !important;

			.ant-steps-icon {
				display: inline;
				color: #748ea0;
				top: -2px;
				display: none;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #748ea0;
					text-align: left;
				}
			}
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.send-banks-error {
		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-icon {
			background: white;
			border: 2px solid #ff4d4f !important;

			.ant-steps-icon {
				display: inline;
				color: #ff4d4f;
				top: -2px;
				display: none;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #ff4d4f;
					text-align: left;
				}
			}
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.send-banks-wait {
		.ant-steps-item-icon {
			margin-left: 10px;
		}

		.ant-steps-item-icon {
			background: white;
			border: 2px solid #c0daee !important;

			.ant-steps-icon {
				display: inline;
				color: #c0daee;
				top: -2px;
				display: none;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #c0daee;
					text-align: left;
				}
			}
		}

		.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
			color: white !important;
		}

		.ant-steps-item-tail {
			margin-left: -10px;
		}

		.ant-steps-item-description {
			width: 60px;
			text-align: center !important;
			font-size: 10px;
			margin-top: 35px;
		}

		.ant-steps-item-container {
			margin-left: 10px !important;
		}

		.ant-steps-item-content {
			margin-left: -10px !important;
		}
	}

	.steps-banks-fein {
		.ant-steps-item-description {
			margin-left: -42px !important;
			width: 68px !important;
		}
	}

	.steps-banks-fein-signed {
		.ant-steps-item-description {
			margin-left: -54px !important;
			width: 76px !important;
		}
	}

	.typography {
		font-weight: 500;
		font-size: 12px;
		color: #2f4858;
	}

	.order-section-icon-banks-selection {
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
		column-gap: 8px;
		row-gap: 8px;
	}

	.border-icon-banks {
		border: 1px solid #02c3cd;
		border-radius: 4px;
		padding: 16px 0;
		text-align: center;
		cursor: pointer;
	}

	.border-icon-banks-offers {
		border: 1px solid #ffff;
		width: 32px;
		padding: 3px;
	}

	.card-shadow-common {
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		border-radius: 4px;
	}

	.step-name {
		margin-right: 10px;
	}

	.step-date {
		color: #748ea0;
	}


}

.ant-popover-inner {
	background: #e1f7ff;
}

.ant-popover-arrow-content::before {
	background: #e1f7ff;

}


.gb-modal-banks-selected {
	.ant-modal-header {
		padding-bottom: 16px !important;
	}

	.ant-modal-footer {
		padding-top: 8px !important;
	}
}

.gb-card-24px-border-blue {
	border: 1px solid #c0daee;
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-card-body {
		padding: 24px !important;
	}
}

.gb-checkbox {
	.ant-checkbox-group-item {
		margin-top: 24px;
	}
}

.gb-notification-warning {
	.ant-notification {
		background: #fef0d3 !important;
		padding: 16px !important;
	}
}

.styleTableOfferSelect {
	background-color: #ffffff;
	color: #c0daee;
	font-weight: 600;
	font-size: 12px;

	.ant-table .ant-table-row .ant-table-row-level-0{
		background-color: cadetblue;
	}
}

.styleTableOffer {
	color: #748ea0;
	font-weight: 600;
	font-size: 14px;

	.ant-table .ant-table-row .ant-table-row-level-0{
		background-color: cadetblue;
	}
}

.gb-input-number-only {
	.ant-input-number-input {
		height: 40px !important;
	}
	
}

.row-without-padding{
	.ant-table-cell{
		height: 54px;
		padding: 0px !important;
	}
	&:first-child .ant-table-cell,
	& > .ant-table-cell:first-child {
	  padding-left: 16px !important;
	}
}

.form-table-banks {
	.ant-input, .ant-input-number-input, .ant-input-number {
		height: 40px !important;
		border-radius: 4px!important;
	}
	.ant-select-selector {
		border-radius: 4px!important;
	}

}
