.select-appraisal {
    .ant-select-selector {
        border-radius: 4px !important;
    }
}

.modal-warning-bell.ant-modal .ant-modal-content {
    border-radius: 4px !important;
    padding: 32px !important;
}

.modal-warning-bell.ant-modal .ant-modal-confirm-title, .modal-warning-bell.ant-modal p {
    color: #2F4858;
}

.modal-warning-bell.ant-modal .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
}

.modal-warning-bell.ant-modal .ant-btn-primary, .modal-warning-bell.ant-modal .ant-btn-primary:hover {
    border-radius: 4px !important;
    background-color: #25d6d9;
    margin: 0 auto !important; 
    width: 50% !important;
}



