.mortgage-edit-verified-data-form {
	.ant-form-item-label .ant-typography {
		font-weight: 600;
		font-size: 10px !important;
	}

	.ant-form-item {
		width: '100%' !important;
	}

	.cualificator-item {
		.ant-form-item-label .ant-typography {
			color: var(--main-grey-primary, #2f4858);
		}

		.ant-select-selector,
		.ant-input-number,
		.ant-input {
			border: 1px solid var(--main-grey-l-2, #c0daee) !important;
			background: var(--support-info-l-2, #d4eaff) !important;
		}
	}

	.analyst-item {
		.ant-form-item-label .ant-typography {
			color: var(--Main-Grey-Primary, #2f4858);
		}

		.ant-select-selector,
		.ant-input-number,
		.ant-input {
			border: 1px solid var(--Main-Grey-L2, #c0daee) !important;
			background: rgba(176, 232, 219, 0.5);
		}
	}

	.pre-item {
		.ant-form-item-label .ant-typography {
			color: var(--Main-Grey-Primary, #2f4858);
		}

		.ant-select-selector,
		.ant-input-number,
		.ant-input {
			border: 1px solid var(--Main-Grey-L2, #f5cd57) !important;
			background: #fcf7ec;
		}
	}
}

.borderYellow {
	padding: 16px;
	border: 1px solid var(--Tertiary-Original, #f5cd57);
	border-radius: 8px;
}

.tabs-recommender {
	margin-top: 16px;
	box-shadow: 0px 4px 6px 0px rgba(47, 72, 88, 0.1);
	.ant-tabs-tabpane {
		padding: 24px !important;
    background-color: #fff;
	}
	.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
		color: #2f4858 !important;
		font-size: 14px;
		font-weight: 500;
	}

	.ant-tabs-nav .ant-tabs-tab-btn {
		color: #c0daee !important;
	}
}
