.collapse-document-gibobs {
	.site-collapse-custom-panel,
	.site-collapse-custom-collapse .site-collapse-custom-panel {
		overflow: hidden;
		border: 0px;
		border-radius: 4px;
	}
	.ant-collapse-header {
		background-color: #ffffff;
		border-bottom: 1px solid #f1f7f8;
		border-radius: 4px;
	}
	.ant-collapse-content {
		.ant-collapse-content-box {
			background-color: #ffffff;
			margin-top: 0px;
			padding-top: 16px !important;
			padding: 16px;
			border-radius: 4px;
		}
	}
}
.collapse-list-document-gibobs {
	.site-collapse-custom-panel,
	.site-collapse-custom-collapse .site-collapse-custom-panel {
		overflow: hidden;
		border: 0px;
		border-radius: 4px;
	}
	.ant-collapse-header {
		background-color: #fafefe;
		border-bottom: 1px solid #f1f7f8;
		border-radius: 4px;
	}
	.ant-collapse-content {
		.ant-collapse-content-box {
			background-color: #fafefe;
			margin-top: 0px;
			padding-top: 16px !important;
			padding: 16px;
			border-radius: 4px;
		}
	}
}

.table-color-selector {
	background-color: #c0f0f3;
}

.table-hover-disabled1 {
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: none !important;
	}
	.ant-table-thead >tr>th {
		background: #EFFCFC;
		height: 48px !important;
	}

}
.table-hover-disabled2 {
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: none !important;
	}
	.ant-table-thead >tr>th {
		background: #F3E6BF;
		height: 48px !important;
	}

}

.table-hover-disabled3 {
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: none !important;
	}
	.ant-table-thead >tr>th {
		background: #CBE8BC;
		height: 48px !important;
	}

}


.gb-form-task-documentation {
	.ant-col {
		.label {
			height: 100%;
			font-size: 12px;
			color: #2f4858;
			font-weight: 400;
			width: 113px;
			margin-bottom: 8px;
		}
		.blockForm {
			margin-bottom: 16px;
		}
		.ant-form-item-control {
			.ant-form-item-control-input {
				.ant-radio-wrapper {
					font-size: 14px;
					color: #748ea0;
					font-weight: 400;
				}
				.ant-checkbox-wrapper {
					font-size: 14px;
					color: #748ea0;
					font-weight: 400;
				}
				.ant-picker {
					width: 100%;
					border: 1px solid rgb(192, 218, 238);
					border-radius: 4px;
				}
			}
		}
	}
}

.gb-modal-style-preferences {
	.ant-modal-header {
		padding-bottom: 16px;
	}
	.ant-modal-body {
		padding-bottom: 40px;
	}
	.ant-modal-footer {
		display: none;
	}
}

.details-traces-style {
	font-size: 12px;
	font-weight: 400;
	color: #748ea0;
	width: 114px;
}
